/* You can add global styles to this file, and also import other style files */
// @import '~@angular/material/prebuilt-themes/deeppurple-amber.css';


body {
  margin: 0;
  font-family: 'Open Sans' ;
}

* {
  font-family: 'Open Sans';
  font-style: normal;
  font-variant: normal;

  --bss-blue: #012E4D;

  --responsive-width: 100vw;

  --vh: 1vh;
}

@media (orientation: landscape) {

    * {
        --responsive-width: calc(0.625*100vh);
    }

}

// .mat-progress-spinner circle ,
// .mat-spinner circle {
//   stroke: var(--bss-blue);
// } 

// .mat-calendar-body-selected {
//   background-color: var(--bss-blue);
// }
// .mat-datepicker-toggle-active {
//   color: var(--bss-blue);
// }

.specific-class>.mat-expansion-indicator,
.mat-expansion-indicator:after {
  color: #CCCCCC !important;
}

.mat-expansion-panel {
  display: flex !important;
  flex-direction: column;
}

.mat-expansion-panel-header {
  order: 0;
  // min-height: 65px;
}

html,
body {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  margin: 0px;
  background-color: white;
  overscroll-behavior: none;
  overflow: hidden;
  position: fixed;
  bottom: 0;
}

.mat-tab-header {
  margin-bottom: 5px;
}

.mat-tab-body {
  height: 100%;
  // overflow-y: auto;
}

.mat-tab-label {
  padding: 0 12px !important;
}

.mat-tab-body-wrapper {
  height: 100%;
  overflow-y: hidden !important;
}

.mat-tab-body-content { 
  height: 100%; 
  overflow-y: hidden !important;
}

.mat-expansion-indicator {
  margin-right: 0.8rem;
}

.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
  background: white;
}

.mat-bottom-sheet-container-medium, .mat-bottom-sheet-container-large{    
  width: var(--responsive-width);

}

mat-ink-bar {
  background-color: #002d48 !important;
}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #002d48 !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {  
  color: #002d48 !important;
}

.mat-focused .mat-form-field-label {
  color: #002d48 !important;
}

.mat-form-field-ripple {
  background-color: #002d48 !important;
}

.mat-form-field-empty.mat-form-field-label {
  color: #CCCCCC;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #00314c80 !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #00314c !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: #00314c !important;
}


/**
  ANIMATIONS
  */

@keyframes fadeInDownMenu {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInUpMenu {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeInDownTabMenu {
  100% {
    opacity: 1;
    transform: translateY(1%);
  }

  0% {
    opacity: 0;
    transform: translateY(100%);
  }
}


@keyframes fadeInRight {
  0% {
    /* opacity: 0; */
    transform: translatex(100%);
  }

  100% {
    /* opacity: 1; */
    transform: translatex(1%);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 1;
    transform: translatex(1%);
  }

  100% {
    opacity: 0;
    transform: translatex(100%);
  }
}

video {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: contain;
  object-fit: contain;
}

.mat-expansion-panel{
  box-shadow: none !important;
  border: none !important;
  background:unset!important;
}

.mat-tab-header-pagination.mat-tab-header-pagination-disabled {
  display: none !important;
  overflow: hidden;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background-color: unset!important;
}

.mat-select-value {
    overflow: visible!important;
}

input:disabled, textarea:disabled, input:disabled::placeholder, textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

/* CDK OVERLAY */
.cdk-overlay-pane{
  min-width: 305px !important;
}

.mat-bottom-sheet-container {
  max-height: 94vh !important; // for journalbooking
}
